<script setup lang="ts">
import { NewsletterProps } from "./Newsletter.props"
import { sm, md, lg } from "~/utils/breakpoints"
import { getImageData } from "~/utils/wrapperImage"
import { useUserStore } from "~/stores/user"
import { NotificationAlertProps } from "../NotificationAlert.props"
import { storeToRefs } from "pinia"

const props = defineProps<NewsletterProps>()

const userStore = useUserStore()
const { user } = storeToRefs(userStore)
const isLogged = useIsLogged()
const { t } = useI18n()
const { addNotification } = useNotification()
const { cart } = useCart()

const { refreshDiscountCodes } = useDiscountCodes()

const bgImage = computed(() => {
  if (!!props.bgImageSmall && sm.value) return props.bgImageSmall
  else if (!!props.bgImageMedium && md.value && !lg.value)
    return props.bgImageMedium
  return props.bgImageLarge
})

const subscriptionCompleted = ref(false)

const captionShown = computed(
  () => props.caption ?? t("services.newsletter.caption")
)

const titleShown = computed(() => props.title ?? t("services.newsletter.title"))

const ctaTextShown = computed(
  () => props.ctaText ?? t("services.newsletter.ctaText")
)

const unsubscribeTextShown = computed(
  () => props.unsubscribeText ?? t("services.newsletter.unsubscribeText")
)

const confirmationSubscribeTitleShown = computed(
  () =>
    props.confirmationSubscribeTitle ??
    t("services.newsletter.confirmationSubscribeTitle")
)

const staticBg = getImageData({
  altText: "Background image for newsletter banner",
  url: "/i5oh5hzamkwo/5JQy5VYnKcAf5xt0wFxUJe/1cd8306ad10ba95d0ee8b2ab120b7e66/newsletter-bg.webp",
  width: 1224,
  height: 326
})

const imageShown = computed(() => bgImage.value ?? staticBg)

const subscribeUser = async () => {
  const { error } = await userStore.updateUserSubscriptionNewsletter(
    isLogged.value,
    true
  )

  if (error.value) {
    const notificationErrorSub: NotificationAlertProps = {
      id: Math.random(),
      type: "warning",
      notificationAlertText: t(
        "myAccount.editAddress.notificationErrorUpdateSub"
      ),
      isWithCloseIcon: true
    }
    addNotification(notificationErrorSub)
  } else {
    subscriptionCompleted.value = true

    setTimeout(async () => {
      const updatedDataUser = {
        ...user.value,
        metadata: {
          ...user.value,
          isSubscribed: true
        }
      }
      await userStore.updateUserData(updatedDataUser)

      await refreshDiscountCodes()
    }, 5000)
  }
}

// this banner component must stay visible even after the user has subscribed to newsletter.
// However, it shouldn't appear if the user is already subscribed on component mount
const isAlreadySubscribed = ref<undefined | boolean>(user.value?.isSubscribed)

watch(
  () => user.value?.isSubscribed,
  (newVal) => {
    // this is to solve the issue of isAlreadySubscribed not initializing correctly on page refresh
    //   due to the user data initializing as undefined and getting a thruthy or falsy value only after a short delay
    if (newVal === undefined || isAlreadySubscribed.value !== undefined) return
    isAlreadySubscribed.value = newVal
  }
)
</script>

<template>
  <div
    v-if="isLogged && user && user.email && isAlreadySubscribed === false"
    class="py-6 md:py-12"
  >
    <section
      v-if="subscriptionCompleted"
      class="
        banner-newletter
        flex flex-col
        items-center
        px-4
        pb-24
        pt-14
        text-center
        md:pb-24
        md:pt-[60px]
      "
    >
      <UtilsWrapperImage
        v-bind="imageShown"
        class="
          wrapper-image-full-container-centered
          absolute
          inset-0
          -z-10
          overflow-hidden
          md:rounded-lg
        "
      />
      <UtilsIcon
        name="CheckmarkCircle.svg"
        color="green-main"
        class="h-[60px] w-[60px]"
      />
      <p class="title pb-8 pt-3 md:py-5">
        {{ confirmationSubscribeTitleShown }}
      </p>
    </section>
    <section
      v-else
      class="
        banner-newletter
        flex flex-col
        md:flex-row
        md:justify-between
        md:shadow-01
      "
    >
      <UtilsWrapperImage
        v-bind="imageShown"
        :key="imageShown.image.url"
        class="
          wrapper-image-full-container-centered
          absolute
          inset-0
          -z-10
          overflow-hidden
        "
      />
      <div
        class="
          px-4
          pb-3
          pt-6
          text-center text-black-main
          md:w-5/12
          md:pl-24
          md:pt-10
          md:text-left
          lg:text-black-800
        "
      >
        <h2 class="beaver uppercase">
          {{ captionShown }}
        </h2>
        <p class="title my-2 md:mb-6 md:mt-4">
          {{ titleShown }}
        </p>
        <UtilsMarkdown
          class="pig markdown__link-underlined"
          :content="ctaTextShown"
        />
      </div>
      <UtilsNewsletterForm
        :email="user.email"
        :unsubscribe-text="unsubscribeTextShown"
        @onSubscription="subscribeUser"
        class="px-4 pb-6 pt-4 md:mt-10 md:w-6/12 md:px-20 md:py-8"
      />
    </section>
  </div>
</template>

<style scoped lang="scss">

.banner-newletter {
  @apply relative w-full overflow-hidden md:w-full md:rounded-lg;
}

.title {
  @apply elephant-bold md:mammoth-bold;
}

</style>
